<template>
    <div class="single-header">
        <secondary-header :title="lang('Receipt')" backRoute="default"></secondary-header>
        <main>
            <div class="container select-bills">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div v-if="this.receipt || !this.state.loading" class="content pt-4">
                            <div class="header text-center">
                                <div class="font-weight-bold large">{{ receipt.branch_name }}</div>
                                <div>{{ receipt.branch_address1 }}</div>
                                <div>{{ receipt.branch_address2 }}</div>
                                <div>{{ receipt.branch_address3 }}</div>
                                <div>{{ receipt.branch_postcode }} {{ receipt.branch_city }}, {{ receipt.branch_state }}</div>

                                <div class="mt-3 font-weight-bold large">{{ lang('RECEIPT') }}</div>
                            </div>

                            <div class="mt-4"><b>{{ lang('No') }}: {{ receipt.reference_no }}</b></div>
                            <div><b>{{ lang('Date') }}:</b> {{ receipt.datetime }}</div>

                            <div class="mt-4"><b>{{ lang('Account No.') }}:</b> {{ receipt.location_name }}</div>
                            <div><b>{{ lang('Name') }}:</b> {{ receipt.owner_name }}</div>
                            <div><b>{{ lang('Amount') }}:</b> {{ money(receipt.amount) }}</div>

                            <table v-if="receipt.descriptions" class="my-2">
                                <tr v-for="description in receipt.descriptions" :key="description.id">
                                    <td style="padding: 0 0.5rem !important;">{{ description.fudc_name }} - {{ description.fudc_code }}</td>
                                    <td style="padding: 0 0.5rem !important;">{{ money(description.amount) }}</td>
                                </tr>
                            </table>

                            <div class="mt-4"><b>{{ lang('Pay By') }}:</b> {{ receipt.customer_phone_no }}</div>
                            <div><b>{{ lang('Name') }}:</b> {{ receipt.customer_name }}</div>
                            <div class="mt-1"><b>{{ lang('Payment Method') }}:</b> {{ receipt.payment_method }}</div>

                            <div class="ws-pre-wrap mt-4">{{ receipt.remark }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <button v-show="!state.loading" :disabled="state.loading_share_pdf" :class="{disabled: state.loading_share_pdf}" class="share-button" @click="shareResource">
                <font-awesome-icon icon="share-alt" size="2x"></font-awesome-icon>
            </button>
            
            <loader v-if="state.loading" />
        </main>
    </div>
</template>

<script>

export default {
    props: ['transaction', 'resource_id', 'bill'],

    data() {
        return {
            receipt: this.transaction ? this.transaction : {},
        }
    },

    methods: {
        getResource() {
            this.$set(this.state, 'loading', true)
            axios.post(this.GLOBAL.API_BASE_URL + "transaction/receipt/"+ this.resource_id)
                .then(response => {
                    this.receipt = response.data
                })
                .catch((error) => {
                    defaultErrorManagement(error.response)
                    this.$router.push({name: 'transaction', replace: true})
                }) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        shareResource() {
            if(this.isNative()) {
                window.ReactNativeWebView.postMessage("SHARE|type=gobills-pdf,resource=receipt,resource_id="+ this.resource_id +",api_token="+ localStorage.token)
            }
            else if('share' in window.navigator) {
                this.$set(this.state, 'loading_share_pdf', true)
                axios.post(this.GLOBAL.API_BASE_URL + "pdf", {
                        resource: 'receipt',
                        resource_id: this.resource_id,
                    },
                    {
                        responseType: 'blob',
                    }
                    )
                    .then((response) => {
                        var file = new File([response.data], this.receipt.reference_no +".pdf", {type: "application/pdf"})
                        var files = [file]

                        if(navigator.canShare({ title: this.receipt.reference_no, files })) {
                            navigator.share({ title: this.receipt.reference_no, files })
                                .catch((err) => console.log(err))
                        }
                    })
                    .catch(error=>{
                        error.response.data.text()
                            .then((data) => {
                                error.response.data = JSON.parse(data)
                                defaultErrorManagement(error.response)
                            })
                    })
                    .finally(() => this.$set(this.state, 'loading_share_pdf', false))
            }
        },
    },

    created() {
        this.getResource()
    },

    beforeRouteLeave(to, from, next) {
        to.params.bill = this.bill
        next()
    }
}
</script>

<style lang="scss" scoped>
.large {
    font-size: 1.1rem;
}
</style>