<template>
    <div class="single-header">
        <secondary-header title="Pay Bills" backRoute="default"></secondary-header>

        <main>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="content pt-4">

                            <table width="100%">
                                <tr>
                                    <td>{{ lang('Amount Due') }}</td>
                                    <td>{{ money(100) }}</td>
                                </tr>
                                <tr>
                                    <td>{{ lang('Invoice Date') }}</td>
                                    <td>20/01/2020</td>
                                </tr>
                                <tr>
                                    <td>{{ lang('Category') }}</td>
                                    <td>Category 1</td>
                                </tr>
                                <tr>
                                    <td>{{ lang('Sub Category') }}</td>
                                    <td>Sub Category 1</td>
                                </tr>
                            </table>
                            <hr>
                            <b>{{ lang('Descriptions') }}</b>
                            <table width="100%" class="mb-5">
                                <tr>
                                    <td>{{ lang('Management Charge') }}</td>
                                    <td>{{ money(30) }}</td>
                                </tr>
                                <tr>
                                    <td>{{ lang('Education Charge') }}</td>
                                    <td>{{ money(70) }}</td>
                                </tr>
                                <tr class="font-weight-bold">
                                    <td>{{ lang('Total') }}</td>
                                    <td>{{ money(100) }}</td>
                                </tr>
                            </table>

                            <button type="button" class="btn btn-primary btn-block" @click="enterPin">{{ lang('Confirm') }}</button>

                            <pin-modal v-model="pin" @submit-pin="confirmPayment()"></pin-modal>

                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import PinModal from '@/components/inputs/PinModal.vue'
export default {
    components: {
        PinModal,
    },

    data() {
        return {
            pin: ''
        }
    },

    methods: {
        enterPin() {
            console.log('enter pin')
            $('#pin-modal').modal('show')
        },

        confirmPayment() {
            console.log(this.pin)
        }
    }
}
</script>
