import { render, staticRenderFns } from "./FavoriteUtilities.vue?vue&type=template&id=07ae1939&scoped=true"
import script from "./FavoriteUtilities.vue?vue&type=script&lang=js"
export * from "./FavoriteUtilities.vue?vue&type=script&lang=js"
import style0 from "./FavoriteUtilities.vue?vue&type=style&index=0&id=07ae1939&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07ae1939",
  null
  
)

export default component.exports