<template>
    <div class="single-header">
        <secondary-header :title="lang('Invoice')" backRoute="default"></secondary-header>
        <main>
            <div class="container select-bills">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div v-if="this.transaction || !this.state.loading" class="content pt-4">
                            <div class="header text-center">
                                <div class="font-weight-bold large">{{ invoice.branch_name }}</div>
                                <div>{{ invoice.branch_address1 }}</div>
                                <div>{{ invoice.branch_address2 }}</div>
                                <div>{{ invoice.branch_address3 }}</div>
                                <div>{{ invoice.branch_postcode }} {{ invoice.branch_city }}</div>

                                <div class="mt-3 font-weight-bold large">{{ lang('INVOICE') }}</div>
                            </div>

                            <div class="mt-4"><b>{{ lang('No') }}: {{ invoice.reference_no }}</b></div>
                            <div><b>{{ lang('Date') }}:</b> {{ invoice.datetime }}</div>

                            <div class="mt-4"><b>{{ lang('Account No.') }}:</b> {{ invoice.location_name }}</div>
                            <div><b>{{ lang('Name') }}:</b> {{ invoice.owner_name }}</div>

                            <table class="details mt-3" width="100%">
                                <tr class="border-top border-bottom">
                                    <td>{{ lang('No.') }}</td>
                                    <td class="pl-1">{{ lang('Description') }}</td>
                                    <td class="text-right pl-1">{{ lang('Amount') }}</td>
                                </tr>

                                <template v-for="(desc, i) in invoice.descriptions">
                                    <tr :key="i">
                                        <td>{{ i+1 }}</td>
                                        <td class="pl-1">{{ desc.fudc_name }} {{ desc.description_extend }}</td>
                                        <td></td>
                                    </tr>
                                    <tr v-for="(amount_detail, j) in desc.amount_details" :key="i +'-'+ j" class="sub">
                                        <td></td>
                                        <td class="pl-1">{{ amount_detail.remark }}</td>
                                        <td class="text-right ws-pre pl-2">{{ money(amount_detail.amount) }}</td>
                                    </tr>
                                </template>

                                <tr class="border-top">
                                    <td colspan="2" class="text-right">{{ lang('Subtotal') }}</td>
                                    <td class="text-right ws-pre pl-2">{{ money(invoice.amount) }}</td>
                                </tr>
                                <tr v-if="invoice.adjustment > 0 || invoice.adjustment < 0">
                                    <td colspan="2" class="text-right">{{ lang('Adjustment') }}</td>
                                    <td class="text-right ws-pre pl-2">{{ money(invoice.adjustment) }}</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="text-right">{{ lang('Tax (6%)') }}</td>
                                    <td class="text-right ws-pre pl-2">{{ money(invoice.sst) }}</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="text-right"><b>{{ lang('Total') }}</b></td>
                                    <td class="text-right ws-pre pl-2"><b>{{ money(invoice.total_amount) }}</b></td>
                                </tr>
                            </table>

                            <template v-if="invoice.remark">
                                <div class="mt-4">{{ lang('Remarks') }}:</div>
                                <div class="ws-pre-wrap">{{ invoice.remark }}</div>
                            </template>
                            <div class="mt-4 ws-pre-wrap">{{ invoice.invoice_note }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <button v-show="!state.loading" :disabled="state.loading_share_pdf" :class="{disabled: state.loading_share_pdf}" class="share-button" @click="shareResource">
                <font-awesome-icon icon="share-alt" size="2x"></font-awesome-icon>
            </button>

            <loader v-if="state.loading" />
        </main>
    </div>
</template>

<script>

export default {
    props: ['transaction', 'resource_id', 'bill'],

    data() {
        return {
            invoice: this.transaction ? this.transaction : {},
        }
    },

    methods: {
        getResource() {
            this.$set(this.state, 'loading', true)
            axios.post(this.GLOBAL.API_BASE_URL + "transaction/invoice/"+ this.resource_id)
                .then(response => {
                    this.invoice = response.data
                })
                .catch((error) => {
                    defaultErrorManagement(error.response)
                    this.$router.push({name: 'transaction', replace: true})
                }) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        shareResource() {
            if(this.isNative()) {
                window.ReactNativeWebView.postMessage("SHARE|type=gobills-pdf,resource=invoice,resource_id="+ this.resource_id +",api_token="+ localStorage.token)
            }
            else if('share' in window.navigator) {
                this.$set(this.state, 'loading_share_pdf', true)
                axios.post(this.GLOBAL.API_BASE_URL + "pdf", {
                        resource: 'invoice',
                        resource_id: this.resource_id,
                    },
                    {
                        responseType: 'blob',
                    }
                    )
                    .then((response) => {
                        var file = new File([response.data], this.invoice.reference_no +".pdf", {type: "application/pdf"})
                        var files = [file]

                        if(navigator.canShare({ title: this.invoice.reference_no, files })) {
                            navigator.share({ title: this.invoice.reference_no, files })
                                .catch((err) => console.log(err))
                        }
                    })
                    .catch(error=>{
                        error.response.data.text()
                            .then((data) => {
                                error.response.data = JSON.parse(data)
                                defaultErrorManagement(error.response)
                            })
                    })
                    .finally(() => this.$set(this.state, 'loading_share_pdf', false))
            }
        },
    },

    created() {
        this.getResource()
    },

    beforeRouteLeave(to, from, next) {
        to.params.bill = this.bill
        next()
    }
}
</script>

<style lang="scss" scoped>
.large {
    font-size: 1.1rem;
}

.details {
    td {
        vertical-align: baseline;
    }
}

tr.sub {
    line-height: 1.2rem;
}
</style>
