<template>
    <div class="single-header">
        <secondary-header :title="lang('Business')" backRoute="default" :menuButton="false"></secondary-header>

        <main>
            <div class="container">
                <div class="row justify-content-center digital-card">
                    <div class="col-lg-10 py-3 px-1">
                        <div class="d-flex mb-3 px-2">
                            <div class="d-flex">
                                <img :src="merchant.merchant_logo" alt="" class="logo m-auto" style="max-width: 100%; max-height: 8.3rem;">
                            </div>
                            <div class="flex-fill pl-2">
                                <select name="city_id" id="city_id" v-model="filters.city_id" class="form-control mb-2">
                                    <option value="">{{ lang('-- Select City --') }}</option>
                                    <option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
                                </select>
                                <select name="company_category_id" id="company_category_id" v-model="filters.company_category_id" class="form-control mb-2">
                                    <option value="">{{ lang('-- Select Category --') }}</option>
                                    <option v-for="category in company_categories" :key="category.id" :value="category.id">{{ category.name }}</option>
                                </select>
                                <select name="company_name" id="company_name" v-model="filters.company_name" class="form-control">
                                    <option value="">{{ lang('-- Select Company --') }}</option>
                                    <option v-for="company in company_names" :key="company.name" :value="company.name">{{ company.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div v-for="business in member_businesses" class="business-list" :key="business.id">
                            <div class="font-weight-bold">{{ business.employer_name }}</div>
                            <div class="row">
                                <div class="font-weight-bold col-6">{{ business.employer_city }}</div>
                                <div class="font-weight-bold col-6 pl-0">{{ business.employer_company_category }}</div>                                
                            </div>
                            <div class="row">
                                <div class="col-6">{{ business.name }}</div>
                                <div class="col-6 pl-0">{{ business.employer_phone_no }}</div>
                            </div>
                        </div>

                        <div v-if="!state.loading_resource && member_businesses.length == 0" class="text-center py-5">{{ lang('No data available') }}</div>
                    </div>
                </div>
            </div>

            <loader v-if="state.loading || state.loading_resource" />
        </main>
    </div>
</template>

<script>
export default {
    props: ['merchant_member_id'],

    data() {
        return {
            cities: [],
            company_categories: [],
            company_names: [],
            filters: {
                city_id: '',
                company_category_id: '',
                company_name: '',
            },
            member_businesses: [],
            merchant: {
                merchant_logo: this.$route.query.merchant_logo_url,
            }
        }
    },

    watch: {
        filters: {
            handler: function(newVal) {
                this.getResource()
            },
            deep: true,
        },
    },

    methods: {
        getResource() {
            this.$set(this.state, 'loading_resource', true)
            axios.post(this.GLOBAL.API_BASE_URL +"digital-card/"+ this.merchant_member_id + "/business", this.filters)
                .then(response => {
                    this.member_businesses = response.data.businesses
                    this.merchant = response.data.merchant
                    this.cities = response.data.cities
                    this.company_categories = response.data.company_categories
                    this.company_names = response.data.company_names
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading_resource', false))
        },
    },

    created() {
        this.getResource()
    },
}
</script>

<style lang="scss" scoped>
    .business-list {
        background-color: #c5effd;
        padding: 0.3rem 1rem;
        border-radius: 0.8rem;
        margin-bottom: 0.8rem;
    }
</style>