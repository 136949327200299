<template>
    <div class="single-header">
        <secondary-header :title="lang('Payment Slip')" backRoute="default"></secondary-header>
        <main>
            <div class="container select-bills">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div v-if="resource && !state.loading" class="content pt-4">
                            <div class="header text-center">
                                <div class="pt-4 mb-5">
                                    <img :src="resource.logo_url" alt="" height="100">
                                    <!-- <div class="mt-2"><b>{{ resource.provider_name }}</b></div> -->
                                </div>
                            </div>
                            
                            <div class="mt-3 font-weight-bold large">{{ lang('PAYMENT SLIP') }}</div>

                            <div class="mt-4"><b>{{ lang('No') }}: {{ resource.reference_no }}</b></div>
                            <div><b>{{ lang('Date') }}: {{ resource.datetime }}</b></div>

                            <table class="my-3">
                                <tr>
                                    <td>{{ lang('Transaction Type') }}</td>
                                    <td class="pl-2 pr-1">:</td>
                                    <td>{{ resource.transaction_type }}</td>
                                </tr>
                                <tr>
                                    <td>{{ lang('Requisite') }}</td>
                                    <td class="pl-2 pr-1">:</td>
                                    <td>{{ resource.requisite }}</td>
                                </tr>
                                <tr>
                                    <td>{{ lang('Amount') }}</td>
                                    <td class="pl-2 pr-1">:</td>
                                    <td>{{ money(resource.amount) }}</td>
                                </tr>
                            </table>

                            <div class="mt-4"><b>{{ lang('Pay By') }}:</b> {{ resource.paid_phone_no }}</div>
                            <div><b>{{ lang('Name') }}:</b> {{ resource.paid_by }}</div>
                            <div class="mt-1"><b>{{ lang('Payment Method') }}:</b> {{ resource.payment_method }}</div>

                            <div class="ws-pre-wrap mt-4">{{ resource.remark }}</div>

                            <div class="font-weight-bold text-center mt-4">{{ lang('Payment updates are subject to provider') }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <button v-show="!state.loading" :disabled="state.loading_share_pdf" :class="{disabled: state.loading_share_pdf}" class="share-button" @click="shareResource">
                <font-awesome-icon icon="share-alt" size="2x"></font-awesome-icon>
            </button>
            
            <loader v-if="state.loading" />
        </main>
    </div>
</template>

<script>

export default {
    props: ['transaction', 'resource_id', 'bill'],

    data() {
        return {
            resource: this.transaction ? this.transaction : {},
        }
    },

    methods: {
        getResource() {
            this.$set(this.state, 'loading', true)
            axios.post(this.GLOBAL.API_BASE_URL + "transaction/utility/"+ this.resource_id)
                .then(response => {
                    this.resource = response.data
                })
                .catch((error) => {
                    defaultErrorManagement(error.response)
                    this.$router.push({name: 'transaction', replace: true})
                }) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        shareResource() {
            if(this.isNative()) {
                window.ReactNativeWebView.postMessage("SHARE|type=gobills-pdf,resource=utility,resource_id="+ this.resource_id +",api_token="+ localStorage.token)
            }
            else if('share' in window.navigator) {
                this.$set(this.state, 'loading_share_pdf', true)
                axios.post(this.GLOBAL.API_BASE_URL + "pdf", {
                        resource: 'utility',
                        resource_id: this.resource_id,
                    },
                    {
                        responseType: 'blob',
                    }
                    )
                    .then((response) => {
                        var file = new File([response.data], this.resource.reference_no +".pdf", {type: "application/pdf"})
                        var files = [file]

                        if(navigator.canShare({ title: this.resource.reference_no, files })) {
                            navigator.share({ title: this.resource.reference_no, files })
                                .catch((err) => console.log(err))
                        }
                    })
                    .catch(error=>{
                        error.response.data.text()
                            .then((data) => {
                                error.response.data = JSON.parse(data)
                                defaultErrorManagement(error.response)
                            })
                    })
                    .finally(() => this.$set(this.state, 'loading_share_pdf', false))
            }
        },
    },

    created() {
        this.getResource()
    },

    beforeRouteLeave(to, from, next) {
        to.params.bill = this.bill
        next()
    }
}
</script>

<style lang="scss" scoped>
.large {
    font-size: 1.1rem;
}
</style>