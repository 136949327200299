<template>
    <nav>
        <div>
            <div class="top-logo text-center font-weight-bold">
                <div v-if="backRoute" class="back-button">
                    <a href="#" v-if="backRoute == 'default'" @click.prevent="goBack(-1)"><font-awesome-icon icon="chevron-left" size="lg"></font-awesome-icon></a>
                    <a href="#" v-else-if="backRoute == 'close_app'" @click.prevent="closeApp()"><font-awesome-icon icon="chevron-left" size="lg"></font-awesome-icon></a>
                    <router-link v-else :to="backRoute"><font-awesome-icon icon="chevron-left" size="lg"></font-awesome-icon></router-link>
                </div>
                <div class="title">
                    <router-link :to="{ name: 'utilities' }">{{ title }}</router-link>
                </div>
                <div v-show="menuButton !== false" class="menu-button">
                    <router-link :to="{ name: 'menu' }">
                        <font-awesome-icon icon="bars" size="lg"></font-awesome-icon>
                    </router-link>
                </div>
            </div>
            <slot name="menu"></slot>
        </div>
    </nav>
</template>

<script>
export default {
    props: ['title', 'backRoute', 'homeButton', 'menuButton'],
}
</script>
