<template>
    <div class="single-header">
        <secondary-header title="Pay MyBills" backRoute="default"></secondary-header>

        <main>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <form class="content pt-4" :action="GLOBAL.GONET_ADMIN_URL + 'payments/fpx/checkout'" method="POST">


                            <div class="font-weight-bold text-larger mb-3">
                                {{ lang('Payment Confirmation') }}
                                <div>{{ lang('Service') }}: {{ lang('Gobills') }}</div>
                            </div>

                            <div>
                                <div class="font-weight-bold text-larger">{{ lang('Pay To') }}:</div>

                                <div v-for="(detail, identifier) in prev_input.amount_to_pay_details" :key="identifier" class="mt-3">
                                    <div>{{ detail.branch_name }}</div>
                                    <div class="font-weight-bold">{{ detail.location }}</div>
                                    <div>{{ detail.customer_name }}</div>
                                    <div class="text-right font-weight-bold text-larger">{{ lang('Amount') }} : {{ money(detail.amount) }}</div>
                                </div>
                            </div>

                            <div class="mt-5" hidden>
                                <div class="">
                                    {{ lang('Pay Using') }} :
                                </div>
                                <form id="gonetpay-form" class="content pt-4" :action="GLOBAL.MAIN_GONET_BACKEND_URL + '/ewallet-payment'" method="POST">
                                    <input v-for="(value, field) in payment_details.gopay" type="hidden" :name="field" :value="value" :key="field + value">
                                    <div class="">
                                        <button type="submit" class="btn btn-primary btn-block">{{ lang('GoNetPay') }}</button>
                                    </div>
                                </form>
                                <form id="fpx-form" class="content pt-4" :action="GLOBAL.GONET_ADMIN_URL + '/payments/fpx/checkout'" method="POST">
                                    <input v-for="(value, field) in payment_details.fpx" type="hidden" :name="field" :value="value" :key="field + value">
                                    <div class="">
                                        <button type="submit" class="btn btn-primary btn-block">{{ lang('Online Banking') }} / {{ lang('Credit Card') }}</button>
                                    </div>
                                </form>
                            </div>

                            <float-bottom>
                                <router-link :to="{name: 'bills'}">
                                    <button type="button" class="btn btn-danger btn-block">{{ lang('Cancel') }}</button>
                                </router-link>
                                <a href="#" @click.prevent="confirm()">
                                    <button type="button" class="btn btn-primary btn-block py-0">
                                        {{ lang('Confirm') }} <br>
                                        ({{ money(total_payable_amount) }})
                                    </button>
                                </a>
                            </float-bottom>

                        </form>
                    </div>
                </div>
            </div>
            <loader v-if="state.loading_payment_detail" />
        </main>
    </div>
</template>

<script>

export default {
    props: ['prev_input'],

    data() {
        return {
            total_payable_amount: this.prev_input ? this.prev_input.total_amount_to_pay : 0,
            payment_details: {}
        }
    },

    methods: {
        getPaymentDetails() {
            this.$set(this.state, 'loading_payment_detail', true)
            axios.post(this.GLOBAL.API_BASE_URL + "payment-details", this.prev_input)
                .then(response => {
                    if(response.data == 'invalid') {
                        this.$router.push({name: 'bills', replace: true});
                    } else {
                        this.payment_details = response.data
                    }
                })
                .catch(error=> {
                    if(error.response.status == 403) {
                        Swal.fire({
                            type: 'error',
                            text: error.response.data.message,
                            timer: 10000,
                            onClose: () => {
                                this.$router.go(-1);
                            }
                        })
                    } else {
                        defaultErrorManagement(error.response)
                        this.$router.go(-1);
                    }
                }) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading_payment_detail', false))
        },

        confirm() {
            $('#gonetpay-form').submit()
        }
    },

    created() {
        this.getPaymentDetails()
    },

    beforeRouteEnter (to, from, next) {
        if(to.params.prev_input == undefined) {
            next({name: 'bills.payment-list', replace: true})
        }
        else {
            next()
        }
    },
}
</script>

<style lang="scss" scope>
    .total-payable {
        border: 1px solid #d5d5d5;
        padding: 0.6rem;
        border-radius: 5px;
        background-color: #f1f1f1;
    }

    .text-larger {
        font-size: 110%;
    }
</style>
