<template>
    <div class="text-center my-5">
        validating...
    </div>
</template>

<script>
export default {
    props: ['token'],

    data() {
        return {
            bills: []
        }
    },

    created() {
        if(this.$route.query.token) {
            localStorage.token = this.$route.query.token
        }
        axios.post(this.GLOBAL.API_BASE_URL + "customer-details", {
                token: localStorage.token
            })
            .then(response => {
                if(response.data.status == 'OK') {
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ localStorage.token;
                    this.$router.push({ name:'utilities' })
                } else {
                    Swal.fire({
                        text: 'Session expired',
                        timer: 5000,
                        onClose: () => {
                            window.ReactNativeWebView.postMessage("WINDOW_CLOSED")
                        }
                    })
                }
            })
            .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
            .finally(() => this.$set(this.state, 'loading', false))
    }
}
</script>
