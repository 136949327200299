<template>
    <div class="single-header">
        <secondary-header title="Payment Status"></secondary-header>

        <main>
            <div class="container select-bills">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="content pt-4">

                            <!-- <label class="font-weight-bold mb-0">{{ lang('Pay with') }} <img src="@/assets/images/fpx/fpx-logo.png" alt="FPX" height="25"></label><br> -->

                            <div v-if="payment_status.transaction_return_post" class="text-center mt-5">
                                <div v-if="payment_status.status == 'success' && payment_status.transaction_status == 'SUCCESSFUL'">
                                    <h5 class="text-success"><b>{{ lang('Payment Successful') }}</b></h5>
                                    <h3><b>{{ money(payment_status.amount) }}</b></h3>
                                </div>
                                <div v-if="payment_status.transaction_return_post.transaction_status_code == '99'">
                                    <h5 style="color:#EF6C00;"><b>{{ lang('PENDING FOR AUTHORIZER TO APPROVE') }}</b></h5>
                                </div>
                                <div v-else-if="payment_status.status == 'fail' && payment_status.transaction_status == 'UNSUCCESSFUL'">
                                    <h5 class="text-danger"><b>{{ lang('Payment Unsuccessful') }}</b></h5>
                                    <div v-if="payment_status.transaction_return_post.transaction_status_code == '2A'" class="my-2">
                                        <span v-if="payment_status.banking_type == 'b2b'">{{ lang("Transaction Amount is Lower than the Minimum Limit RM2.00 for B2B1") }}</span>
                                        <span v-else>{{ lang("Transaction Amount is Lower than the Minimum Limit RM1.00 for B2C") }}</span>
                                    </div>
                                    <div v-else-if="payment_status.transaction_return_post.transaction_status_code == '48'" class="my-2">
                                        <span v-if="payment_status.banking_type == 'b2b'">{{ lang("Maximum Transaction Limit Exceeded RM1,000,000 for B2B1") }}</span>
                                        <span v-else>{{ lang("Maximum Transaction Limit Exceeded RM30,000 for B2C") }}</span>
                                    </div>
                                    <div v-else class="my-2">
                                        {{ lang('Something went wrong. Contact us if your bank account has been deducted.') }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="payment_status.transaction_return_post" class="my-5">
                                <table width="100%">
                                    <tr>
                                        <td>{{ lang('Date & Time') }}</td>
                                        <td>&nbsp;:&nbsp;</td>
                                        <td>{{ payment_status.fpx_datetime }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ lang('Pay To') }}</td>
                                        <td>&nbsp;:&nbsp;</td>
                                        <td>{{ payment_status.transaction_return_post.payto }}</td>
                                    </tr>
                                    <tr v-if="payment_status.transaction_return_post.bank">
                                        <td>{{ lang('Bank') }}</td>
                                        <td>&nbsp;:&nbsp;</td>
                                        <td>{{ payment_status.transaction_return_post.bank }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ lang('Transaction Amount') }}</td>
                                        <td>&nbsp;:&nbsp;</td>
                                        <td><b>{{ money(payment_status.amount) }}</b></td>
                                    </tr>
                                    <!-- <tr>
                                        <td>{{ lang('Reference No') }}</td>
                                        <td>&nbsp;:&nbsp;</td>
                                        <td>{{  }}</td>
                                    </tr> -->
                                    <tr v-if="payment_status.transaction_return_post.fpx_transaction_id">
                                        <td>{{ lang('FPX Transaction ID') }}</td>
                                        <td>&nbsp;:&nbsp;</td>
                                        <td>{{ payment_status.transaction_return_post.fpx_transaction_id }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ lang('Seller Order Number') }}</td>
                                        <td>&nbsp;:&nbsp;</td>
                                        <td>{{ payment_status.transaction_return_post.seller_order_no }}</td>
                                    </tr>
                                </table>
                            </div>

                            <router-link :to="{name: 'bills'}">
                                <button type="button" class="btn btn-primary btn-block">Home</button>
                            </router-link>

                            <div class="">
                                <span class="float-right mt-3" style="font-size: 0.7rem;">
                                    <b>{{ lang('Powered by ') }}</b><img src="@/assets/images/fpx/fpx-logo.png" alt="FPX" height="19">
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>

export default {
    props: ['transaction_id'],

    data() {
        return {
            payment_status: {}
        }
    },

    methods: {
        getPaymentStatus() {
            this.$set(this.state, 'loading-payment-status', true)
            axios.post(this.GLOBAL.API_BASE_URL + "payment-status", {transaction_id: this.transaction_id})
                .then(response => {
                    this.payment_status = response.data
                    if(response.data == '') {
                        this.$router.push({ name: 'bills' })
                    }
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading-payment-status', false))
        }
    },

    created() {
        this.getPaymentStatus()
    }
}
</script>
