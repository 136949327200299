<template>
    <div id="pin-modal" class="modal fade" tabindex="-2" role="dialog" aria-labelledby="ModalFormLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <b class="ml-4">{{ lang('Enter Transaction Pin') }} : </b>
                    <pin v-model="pin"></pin>
                    <a href="#" @click.prevent="forgotPin" class="text-info ml-4">
                        {{ lang('Forgot PIN?') }}
                    </a>
                    <a href="#" @click="submitPin" class="float-right mr-4 text-info font-weight-bold">OK</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pin from '@/components/inputs/Pin.vue'
export default {
    components: {
        Pin
    },

    data() {
        return {
            pin: ''
        }
    },

    watch: {
        pin: function(newVal, oldVal) {
            this.$emit('input', newVal)
        }
    },

    methods: {
        submitPin() {
            this.$emit('submit-pin')
            $('#pin-modal').modal('hide')
        },

        forgotPin() {
            $('#pin-modal').modal('hide')
            this.$router.push({ name: 'gopay.transaction-pin-setup' })
        },
    },

    mounted() {
        var _this = this
        $('#pin-modal').on('hidden.bs.modal', function(e) {
            _this.pin = ''
        })
    }
}
</script>
