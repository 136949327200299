<template>
    <div class="gobills single-header">
        <secondary-header :title="lang('GoBills')" backRoute="default"></secondary-header>

        <main>
            <div class="container menu-list">
                <div class="row justify-content-center">
                    <div class="col-md-10 py-3">
                        <div class="font-weight-bold">{{ lang('E-Invoice') }}</div>
                        <div :class="{ 'is-loading': state.loading }">
                            <div v-for="transaction in transactions" class="list" :key="transaction.id" @click="openEinvoice(transaction)">
                                <div class="trans-details">
                                    <hr class="my-1">
                                    <div class="font-weight-bold float-right">
                                        {{ money(transaction.total_amount) }} <span class="text-danger">+</span>
                                    </div>
                                    <div class="font-weight-bold">
                                        {{ transaction.invoice_date }}
                                    </div>
                                    <div>{{ lang('INVOICE') }}</div>
                                    <div>{{ transaction.branch_name }}</div>
                                    <div>{{ transaction.location_name }}</div>
                                    <div>{{ transaction.customer_name }}</div>
                                    <div>{{ transaction.ref_no_branch }}</div>
                                </div>
                            </div>
                            <div v-if="(transactions.length == 0 && state.loading) || state.loading_next" class="loader text-center py-4">
                                {{ lang('loading...') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    data() {
        return {
            transactions: [],

            next_page: '',
            current_page: '',
        }
    },

    methods: {
        getResource() {
            this.$set(this.state, 'loading', true)
            axios.get(this.GLOBAL.API_BASE_URL +"einvoice/list")
                .then((response) => {
                    
                    this.transactions = response.data.data
                    this.next_page = response.data.links.next
                    this.current_page = response.data.meta.current_page

                    this.getNextTransactionPage()
                })
                .catch(error=>defaultErrorManagement(error.response))
                .finally(() => this.$set(this.state, 'loading', false))
        },

        getNextTransactionPage() {
            let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 300 >= document.documentElement.offsetHeight

            if(this.next_page && bottomOfWindow) {
                this.$set(this.state, 'loading_next', true)
                axios.get(this.GLOBAL.API_BASE_URL +"einvoice/list?page="+ (parseFloat(this.current_page) + 1))
                    .then(response => {
                        this.transactions.push.apply(this.transactions, response.data.data)
                        this.next_page = response.data.links.next
                        this.current_page = response.data.meta.current_page

                        this.getNextTransactionPage()
                    })
                    .catch(error=>defaultErrorManagement(error.response))
                    .finally(() => this.$set(this.state, 'loading_next', false))

                this.next_page = ''
            }
        },

        openEinvoice(transaction) {
            this.$router.push({ name: 'menu.einvoice.show', params: {resource_id: transaction.id} })
        }
    },

    created() {
        this.getResource()
    },

    mounted() {
        addEventListener("scroll", this.getNextTransactionPage)
    },

    beforeDestroy() {
        removeEventListener("scroll", this.getNextTransactionPage)
    }
}
</script>