<template>
    <div class="single-header">
        <secondary-header title="Payment List" backRoute="default"></secondary-header>

        <main>
            <div class="container select-bills">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="content main-bill-list pb-5">

                            <div class="header">
                                <div class="container">
                                    <div class="row justify-content-center">
                                        <div class="text-right my-3 col-md-10">
                                            {{ lang('Credit Balance') }}: {{ money(wallet_details.wallet_balance) }}
                                            <a href="#" @click.prevent="closeApp('gopay/reload/main')" class="text-info">
                                                <font-awesome-icon icon="plus-circle" size="lg"></font-awesome-icon>
                                            </a >
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="bills row no-gutters">
                                <div v-for="bill in bills" class="col-lg-6" :key="bill.identifier">
                                    <div class="image merchant" :style="'background: url('+ bill.branch_logo +');'"></div>
                                    <div class="name">
                                        <div>
                                            <div class="branch_name">
                                                {{ bill.customer_name }}
                                            </div>
                                            <div class="branch_name font-weight-bold">
                                                {{ bill.location }}
                                            </div>
                                            <div class="branch_name">
                                                {{ bill.branch_name }}
                                            </div>
                                            <div class="">
                                                <b>{{ lang('Amount Due') }}: {{ money(bill.total_due) }}</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="button">
                                        <div class="text-center">
                                            <span class="info">{{ lang('Fast Pay') }}</span><br>
                                            <input type="number" class="form-control money input-amount" v-model="amount_to_pay[bill.identifier]" step="0.01"
                                                :class="{readonly: !bill_invoice_to_pay.includes(bill.identifier) }"
                                                @click="selectInvoice(bill)"
                                                @blur="invoiceBlur(bill)"
                                            >
                                            <router-link :to="{name: 'bills.payment-single', params: {identifier: bill.identifier}}" class="btn btn-primary btn-sm btn-block mt-1" @click="identifier = '1-1'">{{ lang('Separate') }}</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <router-link :to="{ name: 'bills.payment-fpx' }" class="btn btn-primary btn-block">{{ lang('Online Banking') }} / {{ lang('Credit Card') }}</router-link> -->
                            <!-- <router-link :to="{ name: 'bills.payment-gopay' }" class="btn btn-primary btn-block">{{ lang('GoPay') }}</router-link> -->

                            <float-bottom>
                                <router-link :to="{name: 'bills.payment-method', params: {prev_input: inputs}}">
                                    <button type="button" class="btn btn-primary btn-block" :disabled="!can_submit">
                                        <span
                                            v-if="this.total_current_due > this.wallet_details.wallet_balance"
                                            class="insifficient-balance"
                                            @click.prevent=""
                                        >
                                            {{ lang('Insufficient balance, please reload.') }}
                                        </span>
                                        {{ lang('Pay Now Total') }}: {{ money(total_current_due) }}
                                    </button>
                                </router-link>
                            </float-bottom>
                        </div>
                    </div>
                </div>
            </div>
            <loader v-if="state.loading" />
        </main>
    </div>
</template>

<script>


export default {
    data() {
        return {
            bills: [],
            bill_invoice_to_pay: [],
            amount_to_pay: {},
            total_current_due: 0,
            amount_to_pay_details: {},
            wallet_details: {},

            idnt: '',
        }
    },

    watch: {
        amount_to_pay: {
            handler: function(newVal, oldVal) {
                this.total_current_due = 0
                for (var property in newVal) {
                    if (newVal.hasOwnProperty(property)) {
                        var val = parseFloat(newVal[property])
                        val = isNaN(val) ? 0 : val

                        var val_string = parseFloat(val).toString()
                        if(val_string.indexOf(".") >= 0) {
                            var val_splitted = val_string.split(".")
                            if(val_splitted[1].length > 2) {
                                this.amount_to_pay[property] = val_string.slice(0, val_string.indexOf(".") + 3)
                            }
                        }

                        val = this.moneyRounding(val)
                        this.total_current_due = this.moneyRounding(this.total_current_due + val)

                        this.amount_to_pay_details[property].amount = val
                    }
                }
            },

            deep: true
        }
    },

    computed: {
        inputs: function() {
            return {
                bill_invoice_to_pay: this.bill_invoice_to_pay,
                amount_to_pay: this.amount_to_pay,
                total_amount_to_pay: this.total_current_due,
                amount_to_pay_details: this.amount_to_pay_details
            }
        },

        can_submit: function() {
            return this.total_current_due > 0
                && this.wallet_details.wallet_balance >= this.total_current_due;
        },
    },

    methods: {
        getBills() {
            this.$set(this.state, 'loading', true)
            axios.post(this.GLOBAL.API_BASE_URL +"bills", {pending_only: true})
                .then(response => {
                    this.bills = response.data
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        selectionChange(bill)
        {
            if(this.bill_invoice_to_pay.includes(bill.identifier)) {
                var due = bill.total_due > 0 ? bill.total_due : ''
                this.$set(this.amount_to_pay, bill.identifier, due)
                this.$set(this.amount_to_pay_details, bill.identifier, {
                    branch_name: bill.branch_name,
                    location: bill.location,
                    customer_name: bill.customer_name,
                    amount: due
                })
            } else {
                this.amount_to_pay[bill.identifier] = ''
                delete this.amount_to_pay[bill.identifier]

                this.amount_to_pay_details[bill.identifier] = {}
                delete this.amount_to_pay_details[bill.identifier]
            }
        },

        getCustomerWalletDetails() {
            this.$set(this.state, 'loading_wallet', true)
            axios.get(this.GLOBAL.API_BASE_URL +"customer/wallet-details")
                .then((response) => {
                    this.wallet_details = response.data;
                })
                .finally(() => this.$set(this.state, 'loading_wallet', false));
        },

        selectInvoice(bill) {
            if(!this.bill_invoice_to_pay.includes(bill.identifier)) {
                this.bill_invoice_to_pay.push(bill.identifier)
                this.selectionChange(bill)
            }
        },

        invoiceBlur(bill) {
            if(this.amount_to_pay[bill.identifier] <= 0) {
                if(this.bill_invoice_to_pay.includes(bill.identifier)) {
                    const index = this.bill_invoice_to_pay.indexOf(bill.identifier)
                    if (index > -1) {
                        this.bill_invoice_to_pay.splice(index, 1)
                    }
                    this.selectionChange(bill)
                }
            }
        },
    },

    created() {
        this.getBills()
        this.getCustomerWalletDetails()
    },

    mounted() {
        var _this = this
        $(function() {
            $('body').on('change blur', '.money', function() {
                if(this.value) {
                    $(this).val(_this.moneyRounding(this.value).toFixed(2))
                    
                    // Create native event
                    const event = new Event('input', { bubbles: true });

                    // Dispatch the event on "native" element
                    $(this).get(0).dispatchEvent(event);
                }
            })
        })
    }
}
</script>

<style lang="scss" scoped>
.button {
    font-size: 0.85rem;
    padding: 3px;

    .info {
        font-size: 0.7rem;
    }
}

.main-bill-list {
    .header {
        position: fixed;
        background-color: #ffffff;
        left: 0;
        right: 0;
        z-index: 1;
        border-bottom: 1px solid #d6d6d6;
        height: 3.7rem;
    }

    .bills {
        padding-top: 3.1rem;
    }
}

.select-bills {
    .bills {
        .merchant {
            min-width: 25%;
        }
        .name {
            min-width: 50%;
        }
    }
}

.input-amount {
    &.readonly {
        background-color: #f4f4f4;
    }
}
</style>