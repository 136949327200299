<template>
    <div class="gobills single-header select-bills">
        <secondary-header :title="lang('GoBills')" backRoute="default"></secondary-header>

        <main>
            <div class="container menu-list">
                <div class="row justify-content-center">
                    <div class="col-md-10 py-3">
                        <div class="font-weight-bold">{{ lang('Utilities Favorite') }}</div>

                        <div class="text-right mb-1 pt-2 px-0">
                            {{ lang('Credit Balance') }}: {{ money(wallet_details.wallet_balance) }}
                            <a href="#" @click.prevent="closeApp('gopay/reload/main')" class="text-info">
                                <font-awesome-icon icon="plus-circle" size="lg"></font-awesome-icon>
                            </a>
                        </div>

                        <div class="bills utilities" :class="{ 'is-loading': state.loading }">
                            <hr class="mb-0">
                            <div class="row no-gutters mt-0">
                                <div v-for="(resource, i) in resources" class="col-lg-6" :key="i">
                                    <div class="d-flex" @click="resource.is_maintenance ? '' : payBills(resource)">
                                        <div class="image merchant" :style="'background: url('+ resource.logo_url +');'"></div>
                                        <div class="name">
                                            <div>
                                                <b>{{ resource.name }}</b><br>
                                                <b>{{ resource.short_name }}</b>
                                                <div>{{ lang('Account No.') }}: {{ resource.requisite }}</div>
                                                <div>{{ resource.favorite_name }}</div>
                                            </div>
                                        </div>
                                        <div class="button flex-column align-content-center">
                                            <font-awesome-icon icon="trash" size="lg" class="text-danger ml-auto mt-1 mr-1 mr-sm-3" @click.stop="deleteFavorite(resource)"></font-awesome-icon>
                                            <button v-if="resource.is_maintenance" class="btn btn-primary rounded-pill btn-sm ml-auto mt-2 mb-auto mr-1 mr-sm-3 ws-pre" disabled>{{ lang('Maintenance') }}</button>
                                            <button v-else class="btn btn-outline-primary rounded-pill btn-sm ml-auto mb-auto mt-2 mr-1 mr-sm-3 ws-pre">{{ lang('Pay Now') }}</button>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="(resources.length == 0 && state.loading) || state.loading_next" class="loader text-center py-4">
                                    {{ lang('loading...') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    data() {
        return {
            wallet_details: {},
            resources: [],
            inputs: {},
        }
    },

    methods: {
        getResource() {
            this.$set(this.state, 'loading', true)
            axios.get(this.GLOBAL.API_BASE_URL +"favorite-utilities/list")
                .then((response) => {
                    this.resources = response.data
                })
                .catch(error=>defaultErrorManagement(error.response))
                .finally(() => this.$set(this.state, 'loading', false))
        },

        getCustomerWalletDetails() {
            this.$set(this.state, 'loading_wallet', true)
            axios.get(this.GLOBAL.API_BASE_URL +"customer/wallet-details")
                .then((response) => {
                    this.wallet_details = response.data;
                })
                .finally(() => this.$set(this.state, 'loading_wallet', false));
        },

        payBills(utility) {
            this.$router.push({ name: 'utilities.details', params: {utility:utility}})
        },

        deleteFavorite(resource) {
            Swal.fire({
                    text: this.lang('Are you sure, you want to remove this Favorite'),
                    showCancelButton: true,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios.delete(this.GLOBAL.API_BASE_URL +"favorite-utilities/"+ resource.favorite_id)
                            .then((response) => {
                                if(response.data.status == 'DELETED') {
                                    this.getResource()
                                }
                            })
                    }
                })
        }
    },

    created() {
        this.getResource()
        this.getCustomerWalletDetails()
    },
}
</script>

<style lang="scss" scoped>
.bills.utilities {

    .category-name {
        background-color: #deebf7;
        color: #2070c0;
        padding: 0.3rem 0.8rem;
        margin-top: 1rem;
    }

    .name,
    .image,
    .button {
        border-top: unset;
    }

    .name {
        min-width: 40%;
    }
}

</style>