<template>
    <div class="single-header">
        <secondary-header :title="lang('Debit Note')" backRoute="default"></secondary-header>
        <main>
            <div class="container select-bills">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div v-if="this.transaction || !this.state.loading" class="content pt-4">
                            <div class="header text-center">
                                <div class="font-weight-bold large">{{ debit_note.branch_name }}</div>
                                <div>{{ debit_note.branch_address1 }}</div>
                                <div>{{ debit_note.branch_address2 }}</div>
                                <div>{{ debit_note.branch_address3 }}</div>
                                <div>{{ debit_note.branch_postcode }} {{ debit_note.branch_city }}</div>

                                <div class="mt-3 font-weight-bold large">{{ lang('DEBIT NOTE') }}</div>
                            </div>

                            <div class="mt-4"><b>{{ lang('No') }}: {{ debit_note.reference_no }}</b></div>
                            <div><b>{{ lang('Date') }}:</b> {{ debit_note.datetime }}</div>

                            <div class="mt-4"><b>{{ lang('Account No.') }}:</b> {{ debit_note.location_name }}</div>
                            <div><b>{{ lang('Name') }}:</b> {{ debit_note.owner_name }}</div>

                            <table class="details mt-3" width="100%">
                                <tr class="border-top border-bottom">
                                    <td>{{ lang('No.') }}</td>
                                    <td class="pl-1">{{ lang('Description') }}</td>
                                    <td class="text-right pl-1">{{ lang('Amount') }}</td>
                                </tr>
                                <tr v-for="(desc, i) in debit_note.descriptions" :key="desc.id">
                                    <td>{{ i+1 }}</td>
                                    <td class="pl-1">
                                        <div>{{ desc.fudc_name }} {{ desc.description_extend }}</div>
                                        <div>{{ desc.remark }}</div>
                                    </td>
                                    <td class="text-right ws-pre pl-2">{{ money(desc.total_amount) }}</td>
                                </tr>

                                <tr class="border-top">
                                    <td colspan="2" class="text-right">{{ lang('Subtotal') }}</td>
                                    <td class="text-right ws-pre pl-2">{{ money(debit_note.amount) }}</td>
                                </tr>
                                <tr v-if="debit_note.adjustment > 0 || debit_note.adjustment < 0">
                                    <td colspan="2" class="text-right">{{ lang('Adjustment') }}</td>
                                    <td class="text-right ws-pre pl-2">{{ money(debit_note.adjustment) }}</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="text-right">{{ lang('Tax (6%)') }}</td>
                                    <td class="text-right ws-pre pl-2">{{ money(debit_note.sst) }}</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="text-right"><b>{{ lang('Total') }}</b></td>
                                    <td class="text-right ws-pre pl-2"><b>{{ money(debit_note.total_amount) }}</b></td>
                                </tr>
                            </table>

                            <template v-if="debit_note.remark">
                                <div class="mt-4">{{ lang('Remarks') }}:</div>
                                <div class="ws-pre-wrap">{{ debit_note.remark }}</div>
                            </template>
                            <div class="mt-4 ws-pre-wrap">{{ debit_note.debit_note_note }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <button v-show="!state.loading" :disabled="state.loading_share_pdf" :class="{disabled: state.loading_share_pdf}" class="share-button" @click="shareResource">
                <font-awesome-icon icon="share-alt" size="2x"></font-awesome-icon>
            </button>

            <loader v-if="state.loading" />
        </main>
    </div>
</template>

<script>

export default {
    props: ['transaction', 'resource_id', 'bill'],

    data() {
        return {
            debit_note: this.transaction ? this.transaction : {},
        }
    },

    methods: {
        getResource() {
            this.$set(this.state, 'loading', true)
            axios.post(this.GLOBAL.API_BASE_URL + "transaction/debit-note/"+ this.resource_id)
                .then(response => {
                    this.debit_note = response.data
                })
                .catch((error) => {
                    defaultErrorManagement(error.response)
                    this.$router.push({name: 'transaction', replace: true})
                }) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        shareResource() {
            if(this.isNative()) {
                window.ReactNativeWebView.postMessage("SHARE|type=gobills-pdf,resource=debit_note,resource_id="+ this.resource_id +",api_token="+ localStorage.token)
            }
            else if('share' in window.navigator) {
                this.$set(this.state, 'loading_share_pdf', true)
                axios.post(this.GLOBAL.API_BASE_URL + "pdf", {
                        resource: 'debit_note',
                        resource_id: this.resource_id,
                    },
                    {
                        responseType: 'blob',
                    }
                    )
                    .then((response) => {
                        var file = new File([response.data], this.debit_note.reference_no +".pdf", {type: "application/pdf"})
                        var files = [file]

                        if(navigator.canShare({ title: this.debit_note.reference_no, files })) {
                            navigator.share({ title: this.debit_note.reference_no, files })
                                .catch((err) => console.log(err))
                        }
                    })
                    .catch(error=>{
                        error.response.data.text()
                            .then((data) => {
                                error.response.data = JSON.parse(data)
                                defaultErrorManagement(error.response)
                            })
                    })
                    .finally(() => this.$set(this.state, 'loading_share_pdf', false))
            }
        },
    },

    created() {
        this.getResource()
    },

    beforeRouteLeave(to, from, next) {
        to.params.bill = this.bill
        next()
    }
}
</script>

<style lang="scss" scoped>
.large {
    font-size: 1.1rem;
}

.details {
    td {
        vertical-align: baseline;
    }
}
</style>
