<template>
    <div class="single-header">
        <main>
            <div class="container select-bills">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="content pt-4">
                            <div class="my-5" style="min-height: 16rem;">
                                <div v-if="payment_status.status">
                                    <div class="text-center">
                                        <div v-if="payment_status.status == 'SUCCESS'">
                                            <font-awesome-icon :icon="['far', 'check-circle']" size="4x" class="text-success mb-3"></font-awesome-icon>
                                            <h5 class="text-success"><b>{{ lang('Payment Successful') }}</b></h5>
                                            <h3><b>{{ money(payment_status.amount) }}</b></h3>
                                        </div>
                                        <div v-else-if="payment_status.status == 'FAIL'">
                                            <font-awesome-icon :icon="['far', 'times-circle']" size="4x" class="text-danger mb-3"></font-awesome-icon>
                                            <h5 class="text-danger"><b>{{ lang('Payment Unsuccessful') }}</b></h5>

                                            <div v-if="payment_status.transaction_status_code == '06'">
                                                {{ lang('Monthly payment limit has been exceeded.') }}
                                            </div>
                                        </div>
                                    </div>

                                    <table class="mt-5">
                                        <tr>
                                            <td>{{ lang('Date') }}</td>
                                            <td class="pl-2 pr-1">:</td>
                                            <td>{{ payment_status.transaction_date }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ lang('Service') }}</td>
                                            <td class="pl-2 pr-1">:</td>
                                            <td>{{ lang('GoBills') }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <router-link :to="{name: 'utilities'}" class="btn btn-primary btn-block text-left">
                                {{ lang('Home') }}
                                <div v-if="timer" class="float-right">{{ timer }}</div>
                            </router-link>

                            <router-link :to="{name: 'transaction'}" class="btn btn-primary btn-block text-left mt-3">
                                {{ lang('Receipt') }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>

export default {
    props: ['transaction_id'],

    data() {
        return {
            payment_status: {},
            timer: '',
            interval_cd: '',
        }
    },

    methods: {
        getPaymentStatus() {
            this.$set(this.state, 'loading-payment-status', true)
            axios.post(this.GLOBAL.API_BASE_URL + "payment-status", {transaction_id: this.transaction_id})
                .then(response => {
                    this.payment_status = response.data
                    if(response.data == '') {
                        this.$router.push({ name: 'utilities' })
                    }
                    this.countdown(10);
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading-payment-status', false))
        },

        countdown(lock_seconds) {
            this.interval_cd = setInterval(() => {
                lock_seconds = lock_seconds - 1;
                this.timer = moment.utc(lock_seconds * 1000).format("mm:ss");

                if (lock_seconds <= 0) {
                    clearInterval(this.interval_cd);
                    this.$router.push({name: 'utilities'})
                }
            }, 1000);
        },
    },

    created() {
        this.getPaymentStatus()
    },

    beforeRouteLeave(to, from, next) {
        clearInterval(this.interval_cd)
        next()
    }
}
</script>
