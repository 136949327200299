<template>
    <div class="">
        <div class="pin-section">
            <input type="number" v-model="pin[0]" class="pin form-control" maxlength="1">
            <input type="number" v-model="pin[1]" class="pin form-control" maxlength="1">
            <input type="number" v-model="pin[2]" class="pin form-control" maxlength="1">
            <input type="number" v-model="pin[3]" class="pin form-control" maxlength="1">
            <input type="number" v-model="pin[4]" class="pin form-control" maxlength="1">
            <input type="number" v-model="pin[5]" class="pin form-control" maxlength="1">
        </div>

        <input type="number" v-model="pin_combine" class="pin_combine" maxlength="6">
    </div>
</template>

<script>
export default {
    props: ['value'],
    data() {
        return {
            pin: [],
            pin_combine: ''
        }
    },

    watch: {
        pin: function(newVal, oldVal) {
            this.$emit('input', newVal.join(''))
        },
        value: function(newVal, oldVal) {
            if(newVal == '') {
                this.pin = []
                this.pin_combine = ''
            }
        }
    },

    mounted() {
        var _this = this
        $('input.pin').on('focus', function() {
            $('.pin_combine').focus()
        })
        $('.pin_combine').on('input', function() {
            if(this.value.length > 6) {
                _this.pin_combine = this.value.slice(0,6)
                this.value = this.value.slice(0,6)
            }
            if(this.value.length >= 6) {
                $('.pin_combine').blur()
            }
            if(this.value == '') {
                _this.pin_combine = ''
                $('.pin_combine').val('')
            }
            _this.pin = this.value.split('')
        })

        $('.pin_combine').on('focus', function() {
            $('input.pin').addClass('focus')
        })
        $('.pin_combine').on('blur', function() {
            $('input.pin').removeClass('focus')
        })
    }
}
</script>

<style lang="scss" scope>
    input {
        &.pin_combine {
            color: #ffffff00;
            font-size: 0.5rem;
            border: none;
            position: absolute;
            left: -150%;

            &:focus {
                outline: none;
            }
        }

        &.pin {
            -webkit-text-security: disc;
        }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type=number] {
        -moz-appearance:textfield; /* Firefox */
    }
</style>
