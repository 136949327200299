<template>
    <div class="">
        <secondary-header title="Pay Bills" backRoute="default">
            <template v-slot:menu>
                <div class="main-menu text-only">
                    <ul class="nav nav-pills nav-fill container">
                        <li class="nav-item">
                            <a @click.prevent="payment_method = 'fpx'" class="nav-item" :class="{active: payment_method == 'fpx'}">
                                <span>{{ lang('Online Banking') }} / {{ lang('Credit or Debit Card') }}</span>
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a href="#" @click.prevent="payment_method = 'fpx_card'" :class="{active: payment_method == 'fpx_card'}">
                                <span>{{ lang('Credit / Debit Card') }}</span>
                            </a>
                        </li> -->
                    </ul>
                </div>
            </template>
        </secondary-header>

        <main>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="content pt-3">

                            <div class="mb-2">
                                Customer Support:
                                <b><a href="tel:0168300065">016-8300065</a></b> |
                                <b><a href="tel:0168300109">016-8300109</a></b>
                            </div>

                            <form method="POST" :action="GLOBAL.MAIN_GONET_BACKEND_URL +'/fpx/selectBank'" autocomplete="off">
                                <input type="hidden" name="customer_token" :value="localStorageGetItem('token')">
                                <input type="hidden" name="payment_method" v-model="payment_method">

                                <!-- <label class="font-weight-bold">{{ lang('Pay with') }} <img src="@/assets/images/fpx/fpx-logo.png" alt="FPX" height="25"> -->
                                    <!-- <span v-if="payment_method == 'fpx'">(Current and Savings Account)</span>
                                    <span v-if="payment_method == 'fpx_card'">(Credit Card Account)</span> -->
                                </label><br>

                                <div class="form-group row">
                                    <div class="col-12">
                                        <label>{{ lang('Bank') }} <span class="required-tag">*</span></label>
                                        <select class="form-control" name="buyerBankId" :disabled="state.loading" v-model="buyerBankId">
                                            <option value="">Select Bank</option>
                                            <option v-for="bank in banks" :value="bank.code" :disabled="bank.status == 'B'">{{ bank.display_name }} <span v-if="bank.status == 'B'">(offline)</span></option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-12">
                                        <label>{{ lang('Amount') }}</label>
                                        <input type="number" class="form-control" :placeholder="lang('RM')" name="txnAmount" v-model="txnAmount" disabled>
                                    </div>
                                </div>

                                <div class="form-group row mt-5 tnc">
                                    <div class="col-12 mt-3">
                                        By clicking on the "Proceed" button, you hereby agree with <a href="https://www.mepsfpx.com.my/FPXMain/termsAndConditions.jsp" target="_blank">FPX's Terms & Conditions</a>
                                    </div>
                                </div>

                                <div class="form-group row mb-0">
                                    <div class="col-12">
                                        <input type="hidden" name="buyerBankId" v-model="buyerBankId">
                                        <button type="submit" class="btn btn-info btn-block" :disabled="!can_submit" @click="loader">{{ lang('Proceed') }}</button>

                                        <span class="float-right mt-3" style="font-size: 0.7rem;">
                                            <b>{{ lang('Powered by ') }}</b><img src="@/assets/images/fpx/fpx-logo.png" alt="FPX" height="19">
                                        </span>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>

export default {
    data() {
        return {
            min_topup: 30,

            txnAmount: 300,
            payment_method: 'fpx',
            buyerBankId: '',
            aggreement: false,
            banks: [],

            favorite_banks: [],

            promo_code: '',
            promo_code_status: '',

            wallet_balance: 0
        }
    },

    computed: {
        can_submit: function() {
            return !this.state.loading && this.is_amount_available && this.buyerBankId && this.is_bank_available && this.is_promo_code_available
        },

        is_amount_available: function() {
            return this.txnAmount >= this.min_topup && parseInt(this.txnAmount)
        },

        is_bank_available: function() {
            return this.banks.length > 0 ? (this.banks.find(bank => bank.code == this.buyerBankId).status == 'A') : false
            // return this.favorite_banks.length > 0 ? (this.favorite_banks.find(bank => bank.code == this.buyerBankId).status == 'A') : false
        },

        is_promo_code_available: function() {
            return this.promo_code == '' || this.promo_code_status == 'valid'
        }
    },

    methods: {
        getCurrentBalance() {
            this.$set(this.state, 'loading_balance', true)
            axios.get(this.GLOBAL.API_BASE_URL +"customer/wallet_balance")
                .then(response => {
                    this.wallet_balance = response.data
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading_balance', false))
        },

        getBankList() {
            this.$set(this.state, 'loading', true)
            axios.get(this.GLOBAL.API_BASE_URL +"bank/list")
                .then(response => {
                    this.banks = response.data
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        moneyValidation(e) {
            this.txnAmount = _.round(e.target.value, 2)
        },

        loader() {
            fireLoader()
        }
    },

    created() {
        // this.getFavoriteBank()
        // this.getCurrentBalance()
        // this.getBankList()
    },
}
</script>
