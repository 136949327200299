<template>
    <div class="gobills single-header">
        <secondary-header :title="lang('GoBills')" backRoute="default"></secondary-header>

        <main>
            <div class="container menu-list">
                <div class="row justify-content-center">
                    <div class="col-md-10 py-3">
                        <div v-if="transaction.id">
                            <div class="text-center mt-2">
                                <h5 class="font-weight-bold">{{ transaction.branch }}</h5>
                                <div>{{ transaction.branch_address1 }}</div>
                                <div>{{ transaction.branch_address2 }}</div>
                                <div>{{ transaction.branch_postcode }} {{ transaction.branch_city }}, {{ transaction.branch_state }}</div>
                                <div><b>{{ transaction.branch_phone }}</b></div>
                                <div><b>{{ transaction.branch_email }}</b></div>
                            </div>
                            
                            <div class="text-right">
                                <h5>{{ lang('E-Invoice') }}</h5>
                                <div>{{ lang('Document Code') }}: {{ transaction.ref_no_branch }}</div>
                                <div>{{ lang('Invoice Date and Time') }}: {{ dateUi(transaction.invoice_date, 'DD/MM/YYYY') }}</div>
                            </div>

                            <div class="mt-2">
                                <div><b>{{ lang('Supplier') }}</b></div>
                                <div>{{ lang('TIN') }}: {{ transaction.branch_tin_no }}</div>
                                <div>{{ lang('SST ID') }}: {{ transaction.branch_sst_id }}</div>
                                <div>{{ lang('MSIC Code') }}: {{ transaction.branch_msic_code }}</div>
                            </div>

                            <div class="mt-2">
                                <div><b>{{ lang('Buyer') }}</b></div>
                                <div>{{ lang('TIN') }}: {{ transaction.customer_tin_no }}</div>
                                <div>{{ lang('Name') }}: {{ transaction.customer_name }}</div>
                                <div>{{ lang('ID No.') }}: {{ transaction.customer_document_no }}</div>
                                <div>{{ lang('Address') }}:</div>
                                <div class="ml-2">
                                    <div>{{ transaction.customer_address1 }}</div>
                                    <div>{{ transaction.customer_address2 }}</div>
                                    <div>{{ transaction.customer_address3 }}</div>
                                    <div>{{ transaction.customer_postcode }} {{ transaction.customer_city }}, {{ transaction.customer_state }}</div>
                                </div>
                                <div>{{ lang('HP') }}: {{ transaction.customer_phone }}</div>
                                <div>{{ lang('Email') }}: {{ transaction.customer_email }}</div>
                            </div>

                            <div class="mt-2">
                                <table class="table table-sm border-bottom mb-2">
                                    <tr>
                                        <th>{{ lang('Classification') }}</th>
                                        <th>{{ lang('Description') }}</th>
                                        <th class="text-right">{{ lang('Amount') }}</th>
                                    </tr>
                                    <tr v-for="(invoice_detail, i) in transaction.invoice_details" :key="i">
                                        <td>{{ invoice_detail.code }}</td>
                                        <td>{{ invoice_detail.description }}</td>
                                        <td class="text-right">{{ money(invoice_detail.total_amount) }}</td>
                                    </tr>
                                </table>
                                <table class="table table-borderless table-sm font-weight-bold">
                                    <tr>
                                        <td rowspan="3">
                                            <div v-html="transaction.uuid_qr" class="e-invoice-qr-code"></div>
                                        </td>
                                        <td>
                                            <table>
                                                <tr>
                                                    <td class="text-right">{{ lang('Subtotal') }}:</td>
                                                    <td class="text-right">{{ money(transaction.amount) }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-right">{{ lang('Tax') }}:</td>
                                                    <td class="text-right">{{ money(transaction.amount_tax) }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-right">{{ lang('Total') }}:</td>
                                                    <td width="1%" class="text-right ws-pre pl-3">{{ money(transaction.total_amount) }}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>

                                <div><b>{{ lang('Digital Signature') }}</b></div>
                                <div>{{ transaction.uuid }}</div>
                                <div>{{ lang('Date and Time') }}: {{ transaction.generated_at }}</div>
                                <div>{{ lang('This document is a visual presentation of the e-Invoice') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <button v-show="!state.loading" :disabled="state.loading_share_pdf" :class="{disabled: state.loading_share_pdf}" class="share-button" @click="shareResource">
                <font-awesome-icon icon="share-alt" size="2x"></font-awesome-icon>
            </button>
        </main>
    </div>
</template>

<script>
export default {
    props: ['resource_id'],
    
    data() {
        return {
            transaction: {},
        }
    },

    methods: {
        getResource() {
            this.$set(this.state, 'loading', true)
            axios.get(this.GLOBAL.API_BASE_URL +"einvoice/"+ this.resource_id)
                .then((response) => {

                    this.transaction = response.data
                })
                .catch(error=>defaultErrorManagement(error.response))
                .finally(() => this.$set(this.state, 'loading', false))
        },

        shareResource() {
            if(this.isNative()) {
                window.ReactNativeWebView.postMessage("SHARE|type=gobills-pdf,resource=einvoice,resource_id="+ this.resource_id +",api_token="+ localStorage.token)
            }
            else if('share' in window.navigator) {
                this.$set(this.state, 'loading_share_pdf', true)
                axios.post(this.GLOBAL.API_BASE_URL + "pdf", {
                        resource: 'einvoice',
                        resource_id: this.resource_id,
                    },
                    {
                        responseType: 'blob',
                    }
                    )
                    .then((response) => {
                        var file = new File([response.data], this.transaction.ref_no_master +".pdf", {type: "application/pdf"})
                        var files = [file]

                        if(navigator.canShare({ title: this.transaction.ref_no_master, files })) {
                            navigator.share({ title: this.transaction.ref_no_master, files })
                                .catch((err) => console.log(err))
                        }
                    })
                    .catch(error=>{
                        error.response.data.text()
                            .then((data) => {
                                error.response.data = JSON.parse(data)
                                defaultErrorManagement(error.response)
                            })
                    })
                    .finally(() => this.$set(this.state, 'loading_share_pdf', false))
            }
        },
    },

    created() {
        this.getResource()
    }
}
</script>