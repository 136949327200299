<template lang="html">
    <div class="gobills">
        <secondary-header :title="lang('GoBills')" backRoute="close_app">
            <template v-slot:menu>
                <div class="main-menu text-only">
                    <ul class="nav nav-pills nav-fill container">
                        <li class="nav-item">
                            <router-link :to="{ name: 'utilities' }" class="nav-item">
                                <span> {{ lang('Utilities') }}</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'bills' }" class="nav-item">
                                <span> {{ lang('MyBills') }}</span>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{ name: 'transaction' }" class="nav-item">
                                <span> {{ lang('Transaction') }}</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </template>
        </secondary-header>

        <main>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="content">

                            <router-view></router-view>

                        </div>
                    </div>
                </div>
            </div>
        </main>

        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.main-menu .nav-item {
    position: relative;
    &:not(:last-child)::after {
        content: "";
        position: absolute;
        border: 1px solid #c3c3c3;
        right: -1px;
        height: 80%;
        z-index: 1;
    }
}
</style>