<template>
    <div class="single-header">
        <secondary-header :title="lang('Committees Profile')" backRoute="default" :menuButton="false"></secondary-header>

        <main>
            <div class="container">
                <div class="row justify-content-center digital-card">
                    <div v-if="committee_profile.merchant" class="col-lg-10 py-2">
                        <div class="merchant-name row collapsed" data-toggle="collapse" href="#collapse-merchant">
                            <div class="col-10 pl-0">{{ committee_profile.merchant.name }}</div>
                            <div v-if="committee_profile.merchant_meetings.length > 0" class="col-2 text-right px-0">
                                <font-awesome-icon icon="caret-down" class="collapse-caret"></font-awesome-icon>
                            </div>
                        </div>
                        <div id="collapse-merchant" class="collapse meeting remove-x-margin">
                            <div v-for="meeting in committee_profile.merchant_meetings" :key="meeting.id">
                                <div class="meeting-name d-flex collapsed" data-toggle="collapse" :href="'#collapse-meeting-'+ meeting.id" aria-expanded="false">
                                    <div class="col-5">{{ meeting.term }}</div>
                                    <div class="col-5 pl-0">{{ meeting.period }}</div>
                                    <div v-if="meeting.committees.length > 0" class="col-2 text-right pl-0">
                                        <font-awesome-icon icon="caret-down" class="collapse-caret"></font-awesome-icon>
                                    </div>
                                </div>
                                <div :id="'collapse-meeting-'+ meeting.id" class="collapse meeting-details">
                                    <div v-for="committee in meeting.committees" class="committee d-flex" :key="committee.id">
                                        <div class="pr-2 font-weight-bold">{{ committee.committee_position.name }}:</div>
                                        <div class="flex-fill name">{{ committee.merchant_member.name2 ? committee.merchant_member.name2 : committee.merchant_member.name }}</div>
                                        <div class="flex-fill text-right pl-2">{{ committee.merchant_member.phone_no }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-for="branch in committee_profile.merchant_branches" class="branch-list remove-x-margin" :key="branch.id">
                            <div class="merchant-name branch d-flex collapsed" data-toggle="collapse" :href="'#collapse-branch-'+ branch.id">
                                <div class="col-10 pl-0">{{ branch.name }}</div>
                                <div v-if="branch.branch_meetings.length > 0" class="col-2 text-right px-0">
                                    <font-awesome-icon icon="caret-down" class="collapse-caret"></font-awesome-icon>
                                </div>
                            </div>
                            <div :id="'collapse-branch-'+ branch.id" class="collapse meeting">
                                <div v-for="meeting in branch.branch_meetings" :key="meeting.id">
                                    <div class="meeting-name d-flex collapsed" data-toggle="collapse" :href="'#collapse-branch-meeting-'+ meeting.id" aria-expanded="false">
                                        <div class="col-5">{{ meeting.term }}</div>
                                        <div class="col-5 pl-0">{{ meeting.period }}</div>
                                        <div v-if="meeting.branch_committees.length > 0" class="col-2 text-right pl-0">
                                            <font-awesome-icon icon="caret-down" class="collapse-caret"></font-awesome-icon>
                                        </div>
                                    </div>
                                    <div :id="'collapse-branch-meeting-'+ meeting.id" class="collapse meeting-details">
                                        <div v-for="committee in meeting.branch_committees" class="committee d-flex" :key="committee.id">
                                            <div class="pr-2 font-weight-bold">{{ committee.committee_position.name }}:</div>
                                            <div class="flex-fill name">{{ committee.merchant_member.name2 ? committee.merchant_member.name2 : committee.merchant_member.name }}</div>
                                            <div class="flex-fill text-right pl-2">{{ committee.merchant_member.phone_no }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <loader v-if="state.loading || state.loading_resource" />
        </main>
    </div>
</template>

<script>
export default {
    props: ['merchant_member_id'],

    data() {
        return {
            committee_profile: {},
        }
    },

    methods: {
        getResource() {
            this.$set(this.state, 'loading_resource', true)
            axios.post(this.GLOBAL.API_BASE_URL +"digital-card/"+ this.merchant_member_id + "/committee")
                .then(response => {
                    this.committee_profile = response.data
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading_resource', false))
        },
    },

    created() {
        this.getResource()
    },
}
</script>

<style lang="scss" scoped>
    .merchant-name {
        background-color: #c5dcf4;
        padding: 0.4rem 1rem;
        margin-top: 0.5rem;

        &.branch {
            background-color: #deebf7;
        }
    }

    .meeting-name {
        background-color: #fef2cc;
        padding: 0.4rem 0;
        margin-top: 0.5rem;
    }
    
    .committee {
        border: 1px solid #fef2cc;
        padding: 0.4rem 1rem;
        margin: 0.1rem 0;
        white-space: pre;
        > .name {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .collapse-caret {
        transition-duration: 0.3s;
    }
    .collapsed {
        .collapse-caret {
            transform: rotate(180deg);
        }
    }
</style>